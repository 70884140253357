import * as actionTypes from "../../constants/actionTypes";
import { updateObject } from "../Utility";

const initialState = {
  sidebarShow: true,
  error: null,
  locationAssignmentLoadingPost: false,
  resultData: null,
  toast: [],
  showResult: [],
};

const onChangelocationAssignment = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const postLocationAssignmentPadding = (state, action) => {
  return updateObject(state, {
    locationAssignmentLoadingPost: true,
    resultData: null,
    toast: [],
    showResult: [],
    error: null,
  });
};

const getLocationAssignmentRejected = (state, action) => {
  return updateObject(state, {
    locationAssignmentLoadingPost: false,
    error: action.payload,
    showResult: "Ürünlerin kontrol ediniz.",
    toast: [
      {
        position: "top-right",
        autohide: true && 2000,
        closeButton: true,
        fade: true,
        color: "#b8352e",
      },
    ],
  });
};

const getLocationAssignmentFulfilled = (state, action) => {
  return updateObject(state, {
    toast: [
      {
        position: "top-right",
        autohide: true && 2000,
        closeButton: true,
        fade: true,
        color:
          action.payload.data.commitResult === "Hata tespit edildi."
            ? "#b8352e"
            : null,
      },
    ],
    resultData: action.payload.data.data,
    showResult: action.payload.data.commitResult,
    locationAssignmentLoadingPost: false,
    error: null,
  });
};

const UpdateMainProductsPadding = (state, action) => {
  return updateObject(state, {
    locationAssignmentLoadingPost: true,
    resultData: null,
    toast: [],
    showResult: [],
    error: null,
  });
};

const UpdateMainProductsRejected = (state, action) => {
  return updateObject(state, {
    locationAssignmentLoadingPost: false,
    error: action.payload,
    showResult: "Sunucuyla ilgili bir problem hata meydana geldi.",
    toast: [
      {
        position: "top-right",
        autohide: true && 2000,
        closeButton: true,
        fade: true,
        color: "#b8352e",
      },
    ],
  });
};

const UpdateMainProductsFulfilled = (state, action) => {
  console.log(action.payload);
  return updateObject(state, {
    toast: [
      {
        position: "top-right",
        autohide: true && 2000,
        closeButton: true,
        fade: true,
        color: action.payload?.data?.status !== 200 ? "#b8352e" : null,
      },
    ],
    resultData: action.payload?.data?.data,
    showResult: action.payload?.data?.commitResult,
    locationAssignmentLoadingPost: false,
    error: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCATION_ASSIGNMENT_ON_CHANGE:
      return onChangelocationAssignment(state, action);
    case "LOCATION_ASSIGNMENT_POST_PENDING":
      return postLocationAssignmentPadding(state, action);
    case "LOCATION_ASSIGNMENT_POST_REJECTED":
      return getLocationAssignmentRejected(state, action);
    case "LOCATION_ASSIGNMENT_POST_FULFILLED":
      return getLocationAssignmentFulfilled(state, action);

    case "UPDATE_MAIN_PRODUCT_PENDING":
      return UpdateMainProductsPadding(state, action);
    case "UPDATE_MAIN_PRODUCT_REJECTED":
      return UpdateMainProductsRejected(state, action);
    case "UPDATE_MAIN_PRODUCT_FULFILLED":
      return UpdateMainProductsFulfilled(state, action);
    default:
      return state;
  }
};

export default reducer;
