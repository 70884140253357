import { updateObject } from '../Utility';
import * as type from '../../constants/actionTypes';

const initialState = {
  companyID: '',
  companyIDlist: [],
  companyIDlistLoader: false,

  error: null,

  toast: [],
  showResult: [],
};

const setBackupDownloadPedding = (state, action) => {
  return updateObject(state, { companyIDlistLoader: true });
};
const setBackupDownloadRejected = (state, action) => {
  return updateObject(state, {
    showResult: 'Bir hata meydana geldi\n ' + action.payload,
    error: action.payload,
    companyIDlistLoader: false,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 4000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
  });
};
const setBackupDownloadSuccess = (state, action) => {
  return updateObject(state, {
    companyIDlistLoader: false,
  });
};

const getCompanyIdPedding = (state, action) => {
  return updateObject(state, { companyIDlistLoader: true });
};
const getCompanyIdRejected = (state, action) => {
  return updateObject(state, {
    showResult: 'Bir hata meydana geldi\n ' + action.payload,
    error: action.payload,
    companyIDlistLoader: false,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
  });
};
const getCompanyIdSuccess = (state, action) => {
  return updateObject(state, {
    companyIDlist: action.payload,
    companyIDlistLoader: false,
  });
};

const onChange = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.BACKUP_DATABASE_ON_CHANGE:
      return onChange(state, action);

    case 'BACKUP_DATABASE_GET_COMPANYID_PENDING':
      return getCompanyIdPedding(state, action);
    case 'BACKUP_DATABASE_GET_COMPANYID_REJECTED':
      return getCompanyIdRejected(state, action);
    case 'BACKUP_DATABASE_GET_COMPANYID_FULFILLED':
      return getCompanyIdSuccess(state, action);

    case 'BACKUP_DATABASE_DOWNLOAD_SET_COMPANYID_PENDING':
      return setBackupDownloadPedding(state, action);
    case 'BACKUP_DATABASE_DOWNLOAD_SET_COMPANYID_REJECTED':
      return setBackupDownloadRejected(state, action);
    case 'BACKUP_DATABASE_DOWNLOAD_SET_COMPANYID_FULFILLED':
      return setBackupDownloadSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
