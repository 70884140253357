import { updateObject } from "../Utility";
import * as type from "../../constants/actionTypes";

const initialState = {
  inventoryGetCompaniesLoading: false,
  inventorySetNewLinkLoading: false,
  inventorySetEditLinkLoading: false,

  getInventoryCompaniesData: [],
  error: null,

  toast: [],
  showResult: [],
};

const getInventoryCompaniesPadding = (state, action) => {
  return updateObject(state, { inventoryGetCompaniesLoading: true });
};
const getInventoryCompaniesSuccess = (state, action) => {
  return updateObject(state, {
    getInventoryCompaniesData: action.payload,
    inventoryGetCompaniesLoading: false,
  });
};
const getInventoryCompaniesRejected = (state, action) => {
  return updateObject(state, {
    inventoryGetCompaniesLoading: false,
    error: action.payload,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
  });
};

const inventoryOnChange = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const setInventoryNewLinkPadding = (state, action) => {
  return updateObject(state, { inventorySetNewLinkLoading: true });
};
const setInventoryNewLinkSuccess = (state, action) => {
  return updateObject(state, {
    inventorySetNewLinkLoading: false,
  });
};
const setInventoryNewLinkRejected = (state, action) => {
  return updateObject(state, {
    inventorySetNewLinkLoading: false,
    error: action.payload,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
  });
};

const setInventoryEditLinkPadding = (state, action) => {
  return updateObject(state, { inventorySetEditLinkLoading: true });
};
const setInventoryEditLinkSuccess = (state, action) => {
  return updateObject(state, {
    inventorySetEditLinkLoading: false,
  });
};
const setInventoryEditLinkRejected = (state, action) => {
  return updateObject(state, {
    inventorySetEditLinkLoading: false,
    error: action.payload,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.INVENTORY_ON_CHANGE:
      return inventoryOnChange(state, action);

    case "INVENTORY_SET_LINK_EDIT_PENDING":
      return setInventoryEditLinkPadding(state, action);
    case "INVENTORY_SET_LINK_EDIT_FULFILLED":
      return setInventoryEditLinkSuccess(state, action);
    case "INVENTORY_SET_LINK_EDIT_REJECTED":
      return setInventoryEditLinkRejected(state, action);

    case "INVENTORY_GET_LINK_COMPANIES_PENDING":
      return getInventoryCompaniesPadding(state, action);
    case "INVENTORY_GET_LINK_COMPANIES_FULFILLED":
      return getInventoryCompaniesSuccess(state, action);
    case "INVENTORY_GET_LINK_COMPANIES_REJECTED":
      return getInventoryCompaniesRejected(state, action);

    case "INVENTORY_SET_NEW_LINK_PENDING":
      return setInventoryNewLinkPadding(state, action);
    case "INVENTORY_SET_NEW_LINK_FULFILLED":
      return setInventoryNewLinkSuccess(state, action);
    case "INVENTORY_SET_NEW_LINK_REJECTED":
      return setInventoryNewLinkRejected(state, action);

    default:
      return state;
  }
};

export default reducer;
