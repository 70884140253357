import axios from 'axios';

import { LICENSE_CONTROL_ON_CHANGE } from '../../constants/actionTypes';
import { modalOnchangeActionFunction } from '../actions';

export const licenseControlONCHANGE = ({ props, value }) => {
  return {
    type: LICENSE_CONTROL_ON_CHANGE,
    payload: { props, value },
  };
};

export const LicenseControlGETMARKET = ({ generalToken, _filter }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
      params: {
        filter: _filter,
      },
    };
    dispatch({
      type: 'LICENSE_CONTROL_GET_MARKET',
      payload: axios
        .get('/api/license/get', HEADER)
        .then((response) => response.data),
    });
  };
};



export const LicenseControlSETMARKETSTATUSCHANGE = (
  uid,
  status,
  generalToken
) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };

    const data = { uuid: uid, verify: status };
    let RES = dispatch({
      type: 'LICENSE_CONTROL_MARKET_STATUS_CHANGE',
      payload: axios
        .post('/api/license/verifyupdatelicense', data, HEADER)
        .then((response) => response),
    });
    RES.then((res) => {
      if (
        res.action.type === 'LICENSE_CONTROL_MARKET_STATUS_CHANGE_FULFILLED'
      ) {
        dispatch(
          modalOnchangeActionFunction({ props: 'commonModal', value: false })
        );
        dispatch(
          LicenseControlGETMARKET({ generalToken: generalToken, _filter: 0 })
        );
      }
    });
  };
};

export const LocationTableGETLOCATIONMARKET = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    dispatch({
      type: 'LOCATIONS_GET',
      payload: axios
        .get('/api/Location/GetAllLocations', HEADER)
        .then((response) => response.data),
    });
  };
};

export const LicenseControlSETLOCATIONMARKET = ({
  companyId,
  locationId,
  generalToken,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    const data = {
      company_id: companyId,
      location_id: locationId,
      is_active: true,
    };

    const RES = dispatch({
      type: 'LOCATION_LINK_SET',
      payload: axios
        .post('/api/InventoryLink/Add', data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === 'LOCATION_LINK_SET_FULFILLED') {
        dispatch(
          modalOnchangeActionFunction({ props: 'commonModal', value: false })
        );
        dispatch(
          LicenseControlGETMARKET({ generalToken: generalToken, _filter: 0 })
        );
      }
    });
  };
};
