// NewUserRequestTrendPosAction.js

import {
  GET_TRENDPOS_REQUESTS,
  TRENDPOS_REQUESTS_LOADING,
  UPDATE_TRENDPOS_SHOWRESULT,
} from "../../constants/actionTypes";
import axios from "axios";
import {
  MarketRegisteryGETALLLOCATION,
  MarketRegistrySHOWRESULT,
} from "../actions";

const TRENDPOS_URL = "https://api.trendpos.io/v1/";
// const TRENDPOS_URL = "https://api-dev.trendpos.io/v1/";
const TBX_REQUEST_KEY = "tQpHxWl7o3AzXTSx";

export const TrendposRegisterySHOWRESULT = (message, color = "#2eb85c") => {
  return {
    type: UPDATE_TRENDPOS_SHOWRESULT,
    payload: { message, color },
  };
};

// Action creator for updating the request count
export const newUserRequestTrendpos = (requests, count) => {
  return {
    type: GET_TRENDPOS_REQUESTS,
    requests: requests,
    requestCount: count,
  };
};

export const trendposRequestLoading = (isLoading) => {
  return {
    type: TRENDPOS_REQUESTS_LOADING,
    requestsLoading: isLoading,
  };
};

// Async action creator for checking TrendPos requests
export const checkTrendPosRequests = () => {
  return (dispatch) => {
    let url = TRENDPOS_URL + "auth/pre-registered-users";
    let headers = {
      TBX_REQUEST_KEY: TBX_REQUEST_KEY,
    };

    // Dispatch the GET_TRENDPOS_REQUESTS action to indicate the start of data fetching
    dispatch({ type: GET_TRENDPOS_REQUESTS });

    axios
      .get(url, { headers: headers })
      .then(async (response) => {
        if (response.data.success === true) {
          // Dispatch success action with the received data
          dispatch(
            newUserRequestTrendpos(
              response.data.data,
              response.data.data.length
            )
          );
        } else {
          // Log an error message if the request count is not successful
          console.log("error when trying to get request count for trendpos");
        }
      })
      .catch((err) => {
        // Dispatch a fail action in case of an error
        // This is where you can handle errors and update the state accordingly
        // dispatch(failDataFetching(err));
      });
  };
};

export const sendMigrateUserRequest = (data, generalToken) => {
  return (dispatch) => {
    let url = TRENDPOS_URL + "company/migrate";
    let headers = {
      "Content-Type": "application/json",
      TBX_REQUEST_KEY: TBX_REQUEST_KEY,
    };

    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + generalToken,
      },
    };

    let companySize = 0;
    if (data.companySize === "0 ile 50 m2") companySize = 50;
    else if (data.companySize === "51 ile 100 m2") companySize = 100;
    else if (data.companySize === "100 m2 den büyük") companySize = 101;

    // Stringi Date nesnesine dönüştürme
    var dateObject = new Date(data.registrationDate);

    // Tarihi Y-m-d formatına dönüştürme
    var formattedDate =
      dateObject.getFullYear() +
      "-" +
      ("0" + (dateObject.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dateObject.getDate()).slice(-2);

    let contactPhone = data.contactPhone;
    if (!contactPhone.startsWith("+")) {
      contactPhone = "+" + contactPhone;
    }

    let req = {
      address: data.address,
      alcholTapdk: data.alkoholicTabdkId,
      branchId: data.locationId,
      branchName: data.locationName,
      branchSize: companySize,
      branchTaxNo: data.companyTaxNo,
      branchTypeId: data.locationTypeId,
      canceledDate: data.canceledDate,
      cigarette: data.cigaretteTabdkId,
      companyId: data.storeId,
      companyName: data.storeName,
      contactName: data.contactName,
      contactPhone: contactPhone,
      contactSurname: data.contactSurname,
      currencyId: "TRY",
      latitude: parseFloat(data.coordinateLatitude),
      longitude: parseFloat(data.coordinateLongitude),
      registrationDate: formattedDate,
      subDistrictId: data.subDivision2Id,
      turnOver: data.companyTurnover,
    };

    // Dispatch the TRENDPOS_REQUESTS_LOADING action to indicate the start of data fetching
    dispatch(trendposRequestLoading(true));

    axios
      .post(url, req, { headers: headers })
      .then(async (response) => {
        if (response.data.success === true) {
          axios
            .post("/api/Location/UpdateIsMigrated", data.locationId, HEADER)
            .then(async (response) => {
              dispatch(trendposRequestLoading(false));
              dispatch(
                MarketRegisteryGETALLLOCATION({ generalToken: generalToken })
              );
              dispatch(checkTrendPosRequests());
              dispatch(MarketRegistrySHOWRESULT(response.data));
            })
            .catch((err) => {
              dispatch(trendposRequestLoading(false));
              dispatch(
                MarketRegistrySHOWRESULT(
                  "İşlem gerçekleştirilemedi. Kayıt başarılı fakat manuel olarak is_migrate alanı güncellenmeli."
                )
              );
              console.log("Catch Migrate Error: " + err);
            });
        } else {
          dispatch(trendposRequestLoading(false));
          dispatch(MarketRegistrySHOWRESULT(response.data.message));
        }
      })
      .catch((err) => {
        console.log("Catch Trendpos Error: " + err);
        dispatch(trendposRequestLoading(false));
        dispatch(
          MarketRegistrySHOWRESULT(
            "Trendpos uygulaması tarafında bir hata oluştu. İşlem başarısız."
          )
        );
        // Dispatch a fail action in case of an error
        // This is where you can handle errors and update the state accordingly
      });
  };
};

export const updatePreRegisterUser = (data) => {
  return (dispatch) => {
    let url = TRENDPOS_URL + "auth/register-status-update";
    let headers = {
      TBX_REQUEST_KEY: TBX_REQUEST_KEY,
    };

    let req = {
      UserId: data.id,
      description: data.description,
      potentialCustomer: data.potentialCustomer,
    };

    // Dispatch the TRENDPOS_REQUESTS_LOADING action to indicate the start of data fetching
    dispatch(trendposRequestLoading(true));

    axios
      .put(url, req, { headers: headers })
      .then(async (response) => {
        if (response.data.success === true) {
          dispatch(trendposRequestLoading(false));
          dispatch(checkTrendPosRequests());
          dispatch(
            TrendposRegisterySHOWRESULT(
              "Kayıt başarılı bir şekilde güncellendi.",
              "#2eb85c"
            )
          );
        } else {
          dispatch(trendposRequestLoading(false));
          dispatch(
            TrendposRegisterySHOWRESULT(
              "Bilinmedik bir hata oluştu.",
              "#e55353"
            )
          );
        }
      })
      .catch((err) => {
        console.log("Catch Trendpos Error: " + err);
        dispatch(trendposRequestLoading(false));
        dispatch(
          TrendposRegisterySHOWRESULT(
            "Trendpos uygulaması tarafında bir hata oluştu. İşlem başarısız.",
            "#e55353"
          )
        );
        // Dispatch a fail action in case of an error
        // This is where you can handle errors and update the state accordingly
      });
  };
};
