import axios from "axios";

import { SOFTBOX_ON_CHANGE } from "../../constants/actionTypes";
import { modalOnchangeActionFunction } from "./index";

export const inventoryONCHANGE = ({ props, value }) => {
  return {
    type: SOFTBOX_ON_CHANGE,
    payload: { props, value },
  };
};

export const inventoryGETALLCOMPANIES = ({ generalToken, _filter }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
      params: {
        filter: parseInt(_filter),
      },
    };

    let RES = dispatch({
      type: "INVENTORY_GET_LINK_COMPANIES",
      payload: axios
        .get("/api/InventoryLink/Get", HEADER)
        .then((response) => response.data),
    });
  };
};

export const inventorySETNEWLINK = ({
  generalToken,
  company_id,
  location_id,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = {
      company_id,
      location_id,
      is_active: true,
    };

    const RES = dispatch({
      type: "INVENTORY_SET_NEW_LINK",
      payload: axios
        .post("/api/InventoryLink/Add", data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === "INVENTORY_SET_NEW_LINK_FULFILLED") {
        dispatch(inventoryGETALLCOMPANIES({ generalToken: generalToken }));
        dispatch(
          modalOnchangeActionFunction({ props: "commonModal", value: false })
        );
      }
    });
  };
};

export const inventorySETLINKEDIT = ({
  generalToken,
  company_id,
  location_id,
  is_active,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = {
      company_id,
      location_id,
      is_active,
    };

    const RES = dispatch({
      type: "INVENTORY_SET_LINK_EDIT",
      payload: axios
        .post("/api/InventoryLink/EditLink", data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === "INVENTORY_SET_LINK_EDIT_FULFILLED") {
        dispatch(
          inventoryGETALLCOMPANIES({ generalToken: generalToken, _filter: 0 })
        );
        dispatch(
          modalOnchangeActionFunction({
            props: "yesOrNoModal",
            value: false,
          })
        );
      }
    });
  };
};
