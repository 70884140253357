import axios from "axios";

import { SOFTBOX_ON_CHANGE } from "../../constants/actionTypes";
import { modalOnchangeActionFunction } from "./index";

export const softBoxONCHANGE = ({ props, value }) => {
  return {
    type: SOFTBOX_ON_CHANGE,
    payload: { props, value },
  };
};

export const softBoxGETALLCOMPANIES = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };

    dispatch({
      type: "SOFTBOX_GET_ALL_COMPANIES",
      payload: axios
        .get("/api/Softbox/GetAllCompanies", HEADER)
        .then((response) => response.data),
    });
  };
};
export const softBoxGETLINKCOMPANIES = ({ generalToken, _filter }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
      params: {
        filter: parseInt(_filter),
      },
    };

    dispatch({
      type: "SOFTBOX_GET_ALL_COMPANIES",
      payload: axios
        .get("/api/Softbox/Get", HEADER)
        .then((response) => response.data),
    });
  };
};
export const NewSoftboxLinkAdd = ({ generalToken, companyId, locationId }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = {
      companyId,
      locationId,
      isActive: true,
    };

    const RES = dispatch({
      type: "SOFTBOX_SET_NEW_LINK",
      payload: axios
        .post("/api/Softbox/Add", data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === "SOFTBOX_SET_NEW_LINK_FULFILLED") {
        dispatch(
          SoftboxLicenseControlGETMARKET({
            generalToken: generalToken,
            _filter: 0,
          })
        );
        dispatch(
          modalOnchangeActionFunction({ props: "commonModal", value: false })
        );
      }
    });
  };
};

export const softBoxSETCOMPANYUPDATE = ({
  generalToken,
  companyId,
  locationId,
  isActive,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = {
      companyId,
      locationId,
      isActive,
    };

    const RES = dispatch({
      type: "SOFTBOX_SET_COMPANY_UPDATE",
      payload: axios
        .post("/api/Softbox/EditLink", data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === "SOFTBOX_SET_COMPANY_UPDATE_FULFILLED") {
        dispatch(
          softBoxGETLINKCOMPANIES({ generalToken: generalToken, _filter: 0 })
        );
        dispatch(
          modalOnchangeActionFunction({ props: "yesOrNoModal", value: false })
        );
      }
    });
  };
};

export const softboxSETLINKCOMPANY = ({
  generalToken,
  companyId,
  locationId,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = {
      companyId,
      locationId,
      isActive: true,
    };
    const RES = dispatch({
      type: "SOFTBOX_SET_LINK_COMPANY",
      payload: axios
        .post("/api/Softbox/AddLink", data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === "SOFTBOX_SET_LINK_COMPANY_FULFILLED") {
        dispatch(
          softBoxGETLINKCOMPANIES({ generalToken: generalToken, _filter: 0 })
        );
        dispatch(
          SoftboxLicenseControlGETMARKET({
            generalToken: generalToken,
            _filter: 0,
          })
        );
        dispatch(
          modalOnchangeActionFunction({ props: "linkDoModal", value: false })
        );
        dispatch(
          modalOnchangeActionFunction({
            props: "commonModal",
            value: false,
          })
        );
      }
    });
  };
};

export const MarketRegisteryGETALLDEVISION = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const res = dispatch({
      type: "MARKET_REGISTERY_GET_ALL_DEVISION",
      payload: axios
        .get("/api/Location/GetAllAddress", HEADER)
        .then((response) => response.data),
    });
  };
};

export const MarketRegisterySETLOCATIONMARKET = ({
  companyId,
  locationId,
  generalToken,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = {
      company_id: companyId,
      location_id: locationId,
      is_active: true,
    };
    dispatch({
      type: "MARKET_REGISTERY_SET",
      payload: axios
        .post("/api/InventoryLink/Add", data, HEADER)
        .then((response) => response.data),
    });
  };
};
export const SoftboxLicenseControlGETMARKET = ({ generalToken, _filter }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
      params: {
        filter: _filter,
      },
    };
    dispatch({
      type: "SOFTBOX_LICENSE_CONTROL_GET_MARKET",
      payload: axios
        .get("/api/Softbox/SoftboxLicense", HEADER)
        .then((response) => response.data),
    });
  };
};
