import { updateObject } from '../Utility';
import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  embedLoading: false,

  embeddedInformation: {
    token: '',
    embedUrl: '',
    reportId: '',
    groupId: '',
  },
  error: null,
  toast: [],
  showResult: [],
};

const embeddedTokenOnChange = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const embeddedTokenFulfilled = (state, action) => {
  console.log(action.payload);
  return updateObject(state, {
    embedLoading: false,
    embeddedInformation: action.payload,
  });
};
const embeddedTokenPadding = (state, action) => {
  return updateObject(state, { embedLoading: true });
};
const embeddedTokenRejected = (state, action) => {
  return updateObject(state, {
    embedLoading: false,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EMBEDDED_TOKEN_ON_CHANGE:
      return embeddedTokenOnChange(state, action);
    case 'EMBEDDED_TOKEN_GET_REPORT_FULFILLED':
      return embeddedTokenFulfilled(state, action);
    case 'EMBEDDED_TOKEN_GET_REPORT_PENDING':
      return embeddedTokenPadding(state, action);
    case 'EMBEDDED_TOKEN_GET_REPORT_REJECTED':
      return embeddedTokenRejected(state, action);
    default:
      return state;
  }
};

export default reducer;
