import axios from 'axios';

import { USER_ON_CHANGE } from '../../constants/actionTypes';
import { modalOnchangeActionFunction } from './index';

export const UserONCHANGE = ({ props, value }) => {
  return {
    type: USER_ON_CHANGE,
    payload: { props, value },
  };
};

export const UserGETALLUSER = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    let RES = dispatch({
      type: 'USER_GET_ALL_USER',
      payload: axios
        .get('/api/User/GetAllUsers', HEADER)
        .then((response) => response.data),
    });
  };
};

export const userGETUSERDETAILS = ({ generalToken, userId }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
      params: {
        userId: userId,
      },
    };

    const RES = dispatch({
      type: 'USER_GET_USER_DETAILS',
      payload: axios
        .get('/api/User/GetUserDetail', HEADER)
        .then((response) => response.data),
    });
    RES.then((res) => {
      if (res.action.type === 'USER_GET_USER_DETAILS_FULFILLED') {
        dispatch(
          UserONCHANGE({
            props: 'setUserData',
            value: {
              status: 'update',
              userId: res.value.userId,
              firstName: res.value.firstName,
              lastName: res.value.lastName,
              password: '',
              mobile: res.value.mobile,
              email: res.value.email,
              roleIdList: res.value.roleIdList,
            },
          })
        );
      }
    });
  };
};

export const getUSERALLROLE = ({ generalToken, userId }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    const RES = dispatch({
      type: 'GET_USER_ALL_ROLE',
      payload: axios
        .get('/api/User/GetAllRoles', HEADER)
        .then((response) => response.data),
    });
  };
};

export const userSETUSERREGISTER = ({
  generalToken,
  email,
  password,
  passwordAgain,
  roleIdList,
  firstName,
  lastName,
  mobile,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    if (password === passwordAgain) {
      const data = {
        email,
        password,
        roleIdList: [parseInt(roleIdList)],
        firstName,
        lastName,
        mobile,
      };
      const RES = dispatch({
        type: 'USER_SET_REGISTER',
        payload: axios
          .post('/api/User/AddUser', data, HEADER)
          .then((response) => response.data),
      });
      RES.then((res) => {
        if (res.action.type === 'USER_SET_REGISTER_FULFILLED') {
          dispatch(
            modalOnchangeActionFunction({
              props: 'userModalRegister',
              value: false,
            })
          );
          dispatch(UserGETALLUSER({ generalToken: generalToken }));
        }
      });
    }
  };
};

export const userSETUSERUPDATE = ({
  generalToken,
  userId,
  email,
  password,
  isActive,
  firstName,
  lastName,
  mobile,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    const data = {
      userId,
      email,
      password,
      isActive,
    };

    const RES = dispatch({
      type: 'USER_SET_USER_UPDATE',
      payload: axios
        .post('/api/User/EditUser', data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === 'USER_SET_USER_UPDATE_FULFILLED') {
        const data2 = {
          userId,
          firstName,
          lastName,
          mobile,
        };

        const RES2 = dispatch({
          type: 'USER_SET_USER_UPDATE',
          payload: axios
            .post('/api/User/EditUserDetail', data2, HEADER)
            .then((response) => response.data),
        });
        RES2.then((res) => {
          if (res.action.type === 'USER_SET_USER_UPDATE_FULFILLED') {
            dispatch(
              modalOnchangeActionFunction({
                props: 'userModalRegister',
                value: false,
              })
            );
            dispatch(UserGETALLUSER({ generalToken: generalToken }));
          }
        });
      }
    });
  };
};

export const MarketRegisteryGETALLDEVISION = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    const res = dispatch({
      type: 'MARKET_REGISTERY_GET_ALL_DEVISION',
      payload: axios
        .get('/api/Location/GetAllAddress', HEADER)
        .then((response) => response.data),
    });
  };
};

export const MarketRegisterySETLOCATIONMARKET = ({
  companyId,
  locationId,
  generalToken,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    const data = {
      company_id: companyId,
      location_id: locationId,
      is_active: true,
    };
    const RES = dispatch({
      type: 'MARKET_REGISTERY_SET',
      payload: axios
        .post('/api/InventoryLink/Add', data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {});
  };
};
