import { updateObject } from "../Utility";
import * as type from "../../constants/actionTypes";

const initialState = {
  loading: false,
  allDevision: [],
  setMarketRegistryLoading: false,
  getAllLocationLoading: false,
  getAllLocation: [],
  getLocationType: [],
  setLocationUpdateLoading: false,
  setlocationUpdateData: false,
  error: null,
  toast: [],
  showResult: [],
  failData: [],
  divisions: {
    countryId: "TR",
    divisionId: 0,
    subDivisionId: 0,
    subDivision2Id: 0,
    locationTypeId: 0,
    ProviderId: 0,
    locationSize: "",
    companyTurnover: "",
    companyTakeaway: "",
    hasInventoryApp: "",
    hasBarcodeReader: "",
    hasBarcodeReaderContract: "",
    hasGeneralContract: "",
    alkoholicTabdkId: "",
    cigaretteTabdkId: "",
    companyTaxNo:"",
    installationType:"",
  },
  providerList: [],
  installerList: [],
  marketTag: {
    customerName: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Lokasyon İsmi",
        label: "Lokasyon İsmi *",
        lg: 6,
        required: true,
      },
      value: "",
    },
    customerCode: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Müşteri Kodu",
        label: "Müşteri Kodu",
        lg: 6,
        required: false,
      },
      value: "",
    },

    installer: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Kurulum Yapan Kişi",
        label: "Kurulum Yapan Kişi *",
        lg: 6,
        required: true,
      },
      value: "",
    },
    contactName: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "İsim",
        label: "İsim *",
        lg: 6,
        required: true,
      },
      value: "",
    },
    contactSurname: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Soyisim",
        label: "Soyisim *",
        lg: 6,
        required: true,
      },
      value: "",
    },
    customerPhone: {
      elementType: "input",
      elementConfig: {
        id: "phone",
        type: "phone",
        placeholder: "Telefon-Ülke Kodu ile 90542..",
        label: "Telefon-Ülke Kodu Zorunlu *",
        lg: 6,
        required: true,
      },
      value: "",
    },
    customerAddress: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Adres",
        label: "Adres *",
        lg: 12,
        required: true,
      },
      value: "",
    },
    coordinateLatitude: {
      elementType: "input",
      elementConfig: {
        type: "text",
        id: "text-input",
        name: "text-input",
        placeholder: "Enlem",
        label: "Enlem *",
        lg: 6,
        required: true,
      },
      value: "",
    },
    coordinateLongitude: {
      elementType: "input",
      elementConfig: {
        type: "text",
        id: "text-input",
        name: "text-input",
        placeholder: "Boylam",
        label: "Boylam *",
        lg: 6,
        required: true,
      },
      value: "",
    },
    registrationDate: {
      elementType: "input",
      elementConfig: {
        type: "date",
        placeholder: "Kurulum Tarihi",
        label: "Kurulum Tarihi *",
        lg: 6,
        required: true,
      },
      value: "",
    },
    controlType: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Kontrol Tipi",
        label: "Kontrol Tipi",
        lg: 6,
        required: false,
        maxLenght: 16,
      },
      value: "",
    },
    transferredTo: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Devredilen",
        label: "Devredilen",
        lg: 6,
        required: false,
        maxLenght: 100,
      },
      value: "",
    },
    companyBarcodeReaderId: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Barkod Okuyucu Numarası",
        label: "Barkod Okuyucu Numarası",
        lg: 12,
        required: false,
        maxLenght: 16,
      },
      value: "",
    },
    companyDescription: {
      elementType: "textarea",
      elementConfig: {
        type: "name",
        placeholder: "Açıklama...",
        label: "Açıklama",
        rows: 3,
        lg: 12,
        required: false,
        maxLenght: 200,
      },
      value: "",
    },
  },
  newTrendboxInstaller: "",
  trendbocInstaller: [
    { name: "Umut Karal" },
    { name: "Engin Ezer" },
    { name: "Hüseyin Kaya" },
    { name: "Bahadır Geçgil" },
    { name: "Özgür Menekşe" },
  ],
  newMergedProviderInsatller: [],
  newMergedProviderInsatllerLoading: false,

  ProviderId: [
    { id: 1, name: "SHELL" },
    { id: 2, name: "PMI" },
    { id: 3, name: "JTI" },
    { id: 4, name: "ADEL" },
    { id: 5, name: "AYTEMIZ" },
    { id: 6, name: "PMI-KKTC" },
    { id: 7, name: "PERNOD-RICARD" },
    { id: 8, name: "COCA COLA" },
    { id: 9, name: "BP" },
    { id: 10, name: "BAT" },
    { id: 11, name: "EFES" },
    { id: 12, name: "KTNG" },
    { id: 13, name: "IMPERIAL" },
    { id: 22, name: "BITAYKA" },
  ],
  locationSize: [
    { id: 0, name: "0 ile 50 m2" },
    { id: 1, name: "51 ile 100 m2" },
    { id: 2, name: "100 m2 den büyük" },
    { id: 3, name: "Bigi Vermek İstemiyorum" },
  ],
  companyTurnover: [
    { id: 0, name: "0 - 1500 TL" },
    { id: 1, name: "1500 - 2500 TL" },
    { id: 2, name: "2500 - 3500 TL" },
    { id: 3, name: "Bigi Vermek İstemiyorum" },
    { id: 4, name: "3500 < TL" },
  ],
  companyTakeaway: [
    { id: 0, name: "Var" },
    { id: 1, name: "Yok" },
    { id: 2, name: "Bigi Vermek İstemiyorum" },
  ],
  hasInventoryApp: [
    { id: 0, name: "Yok" },
    { id: 1, name: "Var" },
  ],
  installationType: [
    { id: "Demo", name: "Demo" },
    { id: "Test", name: "Test" },
    { id: "Web", name: "Web" },
    { id: "Trendbox", name: "Trendbox" },
    { id: "Customer", name: "Müşteri" },
    { id: "Trendpos", name: "Trendpos" },
  ],


  pointInformations: {
    // companyLogo: {
    //   elementType: 'input',
    //   elementConfig: {
    //     type: 'name',
    //     placeholder: 'Şirket Logo',
    //     lg: 6,
    //   },
    //   value: '',
    // },
  },
};

const updateShowResult = (state, action) => {
  return updateObject(state, {
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 5000,
        closeButton: true,
        fade: true,
      },
    ],
  });
};

const setLocationUpdatePadding = (state, action) => {
  return updateObject(state, { setLocationUpdateLoading: true });
};
const setLocationUpdateSuccess = (state, action) => {
  return updateObject(state, {
    showResult: action.payload,
    setLocationUpdateLoading: false,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 5000,
        closeButton: true,
        fade: true,
      },
    ],
  });
};
const setLocationUpdateRejected = (state, action) => {
  return updateObject(state, {
    setLocationUpdateLoading: false,
    error: action.payload,
    showResult: "Lütfen * 'lı  alanları doldurunuz",
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 5000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
  });
};

const setLocationRegistryPadding = (state, action) => {
  return updateObject(state, { setMarketRegistryLoading: true });
};
const setLocationRegistrySuccess = (state, action) => {
  let formElementsArray2;
  for (let key in state.marketTag) {
    formElementsArray2 = {
      ...formElementsArray2,
      [key]: { ...state.marketTag[key], value: "" },
    };
  }

  let formElementsArray3;
  for (let key in state.pointInformations) {
    formElementsArray3 = {
      ...formElementsArray3,
      [key]: { ...state.pointInformations[key], value: "" },
    };
  }

  return updateObject(state, {
    pointInformations: formElementsArray3,
    marketTag: formElementsArray2,
    showResult: action.payload,
    setMarketRegistryLoading: false,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 5000,
        closeButton: true,
        fade: true,
      },
    ],
  });
};
const setLocationRegistryRejected = (state, action) => {
  console.log(action.payload);
  return updateObject(state, {
    setMarketRegistryLoading: false,
    error: action.payload,
    showResult: "Lütfen * 'lı  alanları doldurunuz",
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 5000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
  });
};

const getAllDevisionsPadding = (state, action) => {
  return updateObject(state, {});
};
const getAllDevisionsSuccess = (state, action) => {
  return updateObject(state, {
    allDevision: action.payload,
  });
};
const getAllDevisionsRejected = (state, action) => {
  return updateObject(state, {
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
    error: action.payload,
  });
};

const getAllLocationPadding = (state, action) => {
  return updateObject(state, { getAllLocationLoading: true });
};
const getAllLocationSuccess = (state, action) => {
  return updateObject(state, {
    getAllLocation: action.payload,
    getAllLocationLoading: false,
  });
};
const getAllLocationRejected = (state, action) => {
  return updateObject(state, {
    getAllLocationLoading: false,
    error: action.payload,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
  });
};

const setMarketRegisteryPadding = (state, action) => {
  return updateObject(state, { loading: true });
};
const setMarketRegisteryRejected = (state, action) => {
  return updateObject(state, { loading: false, error: action.payload });
};
const setMarketRegisteryFulfilled = (state, action) => {
  return updateObject(state, {
    getLocations: action.payload,
    loading: false,
  });
};

const onChangeRegister = (state, action) => {
  if (action.payload.props === "failData") {
    return updateObject(state, {
      failData: [...state.failData, action.payload.value],
    });
  } else {
    return updateObject(state, {
      [action.payload.props]: action.payload.value,
    });
  }
};

const getLocationTypePadding = (state, action) => {
  return updateObject(state, {});
};
const getLocationTypeSuccess = (state, action) => {
  return updateObject(state, {
    getLocationType: action.payload,
  });
};
const getLocationTypeRejected = (state, action) => {
  return updateObject(state, {
    error: action.payload,
    getAllLocationLoading: false,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 5000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
  });
};

const getAllProviderPadding = (state, action) => {
  return updateObject(state, {});
};
const getAllProviderSuccess = (state, action) => {
  return updateObject(state, {
    providerList: action.payload,
  });
};
const getAllProviderRejected = (state, action) => {
  return updateObject(state, {
    showResult: action.payload,

    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
    error: action.payload,
  });
};

const locationProviderInstallerPadding = (state, action) => {
  return updateObject(state, { newMergedProviderInsatllerLoading: true });
};
const locationProviderInstallerSuccess = (state, action) => {
  return updateObject(state, {
    newMergedProviderInsatller: action.payload,
    newMergedProviderInsatllerLoading: false,
  });
};
const locationProviderInstallerRejected = (state, action) => {
  return updateObject(state, {
    showResult: action.payload,
    newMergedProviderInsatllerLoading: false,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: "#f86c6b",
      },
    ],
    error: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.MARKET_REGISTERY_ON_CHANGE:
      return onChangeRegister(state, action);

    case type.UPDATE_SHOWRESULT:
      return updateShowResult(state, action);

    case "LOCATION_PROVIDER_INSTALLER_ALL_PENDING":
      return locationProviderInstallerPadding(state, action);
    case "LOCATION_PROVIDER_INSTALLER_ALL_REJECTED":
      return locationProviderInstallerRejected(state, action);
    case "LOCATION_PROVIDER_INSTALLER_ALL_FULFILLED":
      return locationProviderInstallerSuccess(state, action);

    case "MARKET_REGISTERY_GET_ALL_PROVIDER_PENDING":
      return getAllProviderPadding(state, action);
    case "MARKET_REGISTERY_GET_ALL_PROVIDER_REJECTED":
      return getAllProviderRejected(state, action);
    case "MARKET_REGISTERY_GET_ALL_PROVIDER_FULFILLED":
      return getAllProviderSuccess(state, action);

    case "MARKET_REGISTERY_GET_ALL_DEVISION_PENDING":
      return getAllDevisionsPadding(state, action);
    case "MARKET_REGISTERY_GET_ALL_DEVISION_REJECTED":
      return getAllDevisionsRejected(state, action);
    case "MARKET_REGISTERY_GET_ALL_DEVISION_FULFILLED":
      return getAllDevisionsSuccess(state, action);

    case "MARKET_REGISTERY_SET_LOCATION_REGISTRY_PENDING":
      return setLocationRegistryPadding(state, action);
    case "MARKET_REGISTERY_SET_LOCATION_REGISTRY_REJECTED":
      return setLocationRegistryRejected(state, action);
    case "MARKET_REGISTERY_SET_LOCATION_REGISTRY_FULFILLED":
      return setLocationRegistrySuccess(state, action);

    case "MARKET_REGISTERY_GET_ALL_LOCATION_PENDING":
      return getAllLocationPadding(state, action);
    case "MARKET_REGISTERY_GET_ALL_LOCATION_REJECTED":
      return getAllLocationRejected(state, action);
    case "MARKET_REGISTERY_GET_ALL_LOCATION_FULFILLED":
      return getAllLocationSuccess(state, action);

    case "MARKET_REGISTERY_SET_PENDING":
      return setMarketRegisteryPadding(state, action);
    case "MARKET_REGISTERY_SET_REJECTED":
      return setMarketRegisteryRejected(state, action);
    case "MARKET_REGISTERY_SET_FULFILLED":
      return setMarketRegisteryFulfilled(state, action);

    case "MARKET_REGISTERY_SET_LOCATION_UPDATE_PENDING":
      return setLocationUpdatePadding(state, action);
    case "MARKET_REGISTERY_SET_LOCATION_UPDATE_REJECTED":
      return setLocationUpdateRejected(state, action);
    case "MARKET_REGISTERY_SET_LOCATION_UPDATE_FULFILLED":
      return setLocationUpdateSuccess(state, action);

    case "MARKET_REGISTERY_GET_LOCATION_TYPE_PENDING":
      return getLocationTypePadding(state, action);
    case "MARKET_REGISTERY_GET_LOCATION_TYPE_REJECTED":
      return getLocationTypeRejected(state, action);
    case "MARKET_REGISTERY_GET_LOCATION_TYPE_FULFILLED":
      return getLocationTypeSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
