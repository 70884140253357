import {
  ON_CHANGE_TEXT,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  SET_AUTH_REDIRECT_PATH,
} from "../../constants/actionTypes";
import axios from "axios";
import moment from "moment";

export const authStart = () => {
  return {
    type: AUTH_START,
  };
};

export const authSuccess = (token, userInfo) => {
  return {
    type: AUTH_SUCCESS,
    idToken: token,
    userInfo: userInfo,
  };
};

export const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.clear();

  return {
    type: AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password,
    };
    
    let url = "/api/Token/NewToken";
    axios
      .post(url, authData)
      .then(async (response) => {
        let date = new Date(response.data.expiration);
        let seconds = (date.getTime() - new Date().getTime()) / 1000;
        const expirationDate = new Date(
          new Date().getTime() + moment(seconds) * 1000
        );

        localStorage.setItem("userId", response.data.id);
        localStorage.setItem("userInfo", response.data.roles);
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("expirationDate", expirationDate);

        dispatch(
          authSuccess(response.data.accessToken, {
            roles: response.data.roles,
            userName: response.data.userName,
          })
        );

        dispatch(checkAuthTimeout(seconds));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken")
        : null;
    if (token === null) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
        window.location.reload();
      } else {
        const userId = localStorage.getItem("userId");
        dispatch(authSuccess(token, userId));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
