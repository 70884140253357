import { updateObject } from '../Utility';
import * as type from '../../constants/actionTypes';

const initialState = {
  setSoftBoxLinkLoading: false,
  getSoftBoxLoading: false,
  getSoftBoxLinkLoading: false,
  setSoftBoxCompanyUpdateLoading: false,

  getSoftBoxAllData: [],
  getSoftBoxLinkData: [],
  error: null,

  toast: [],
  showResult: [],

  softboxLicenseList:[]
};

const getSoftBoxLinkAllCompaniesPadding = (state, action) => {
  return updateObject(state, { getSoftBoxLinkLoading: true });
};
const getSoftBoxLinkAllCompaniesSuccess = (state, action) => {
  return updateObject(state, {
    getSoftBoxLinkData: action.payload,
    getSoftBoxAllData: action.payload,
    getSoftBoxLinkLoading: false,
  });
};
const getSoftBoxLinkAllCompaniesRejected = (state, action) => {
  return updateObject(state, {
    getSoftBoxLinkLoading: false,
    error: action.payload,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
  });
};

const getSoftBoxAllCompaniesPadding = (state, action) => {
  return updateObject(state, { getSoftBoxLinkLoading: true });
};
const getSoftBoxAllCompaniesSuccess = (state, action) => {
  return updateObject(state, {
    getSoftBoxAllData: action.payload,
    getSoftBoxLinkLoading: false,
  });
};

const getSoftBoxAllCompaniesRejected = (state, action) => {
  return updateObject(state, {
    getSoftBoxLinkLoading: false,
    error: action.payload,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
  });
};

const onChangeSoftbox = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const setCompanyUpdatePadding = (state, action) => {
  return updateObject(state, { setSoftBoxCompanyUpdateLoading: true });
};
const setCompanyUpdateRejected = (state, action) => {
  return updateObject(state, {
    showResult: action.payload,
    setSoftBoxCompanyUpdateLoading: false,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
  });
};
const setCompanyUpdateSuccess = (state, action) => {
  return updateObject(state, {
    setSoftBoxCompanyUpdateLoading: false,
    error: action.payload,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
      },
    ],
  });
};

const setSoftBoxPadding = (state, action) => {
  return updateObject(state, { setSoftBoxLinkLoading: true });
};
const setSoftBoxSuccess = (state, action) => {
  return updateObject(state, {
    showResult: action.payload,
    setSoftBoxLinkLoading: false,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
      },
    ],
  });
};
const setSoftBoxRejected = (state, action) => {
  return updateObject(state, {
    setSoftBoxLinkLoading: false,
    error: action.payload,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
  });
};
const setSoftboxLicenceTableSuccess = (state, action) => {
  return updateObject(state, {
    softboxLicenseList: action.payload,
    loading: false,
  });
};
const setSoftboxLicenceTablePadding = (state, action) => {
  return updateObject(state, { loading: true });
};
const setSoftboxLicenceTablerRejected = (state, action) => {
  return updateObject(state, { loading: false, error: action.payload });
};

const setNewSoftBoxSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};
const setNewSoftBoxPadding = (state, action) => {
  return updateObject(state, { loading: true });
};
const setNewSoftBoxRejected = (state, action) => {
  return updateObject(state, { loading: false, error: action.payload });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SOFTBOX_ON_CHANGE:
      return onChangeSoftbox(state, action);

      case 'SOFTBOX_SET_NEW_LINK_PENDING':
        return setNewSoftBoxPadding(state, action);
      case 'SOFTBOX_SET_NEW_LINK_REJECTED':
        return setNewSoftBoxRejected(state, action);
      case 'SOFTBOX_SET_NEW_LINK_FULFILLED':
        return setNewSoftBoxSuccess(state, action);

    case 'SOFTBOX_SET_LINK_COMPANY_PENDING':
      return setSoftBoxPadding(state, action);
    case 'SOFTBOX_SET_LINK_COMPANY_REJECTED':
      return setSoftBoxRejected(state, action);
    case 'SOFTBOX_SET_LINK_COMPANY_FULFILLED':
      return setSoftBoxSuccess(state, action);

    case 'SOFTBOX_SET_COMPANY_UPDATE_PENDING':
      return setCompanyUpdatePadding(state, action);
    case 'SOFTBOX_SET_COMPANY_UPDATE_REJECTED':
      return setCompanyUpdateRejected(state, action);
    case 'SOFTBOX_SET_COMPANY_UPDATE_FULFILLED':
      return setCompanyUpdateSuccess(state, action);

    case 'SOFTBOX_GET_ALL_COMPANIES_PENDING':
      return getSoftBoxAllCompaniesPadding(state, action);
    case 'SOFTBOX_GET_ALL_COMPANIES_REJECTED':
      return getSoftBoxAllCompaniesRejected(state, action);
    case 'SOFTBOX_GET_ALL_COMPANIES_FULFILLED':
      return getSoftBoxAllCompaniesSuccess(state, action);

    case 'SOFTBOX_LINK_GET_ALL_COMPANIES_PENDING':
      return getSoftBoxLinkAllCompaniesPadding(state, action);
    case 'SOFTBOX_LINK_GET_ALL_COMPANIES_REJECTED':
      return getSoftBoxLinkAllCompaniesRejected(state, action);
    case 'SOFTBOX_LINK_GET_ALL_COMPANIES_FULFILLED':
      return getSoftBoxLinkAllCompaniesSuccess(state, action);

      case 'SOFTBOX_LICENSE_CONTROL_GET_MARKET_PENDING':
        return setSoftboxLicenceTablePadding(state, action);
      case 'SOFTBOX_LICENSE_CONTROL_GET_MARKET_REJECTED':
        return setSoftboxLicenceTablerRejected(state, action);
      case 'SOFTBOX_LICENSE_CONTROL_GET_MARKET_FULFILLED':
        return setSoftboxLicenceTableSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
