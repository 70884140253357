import axios from 'axios';

import { EMBEDDED_TOKEN_ON_CHANGE } from '../../constants/actionTypes';

export const embedToken = ({ props, value }) => {
  return {
    type: EMBEDDED_TOKEN_ON_CHANGE,
    payload: { props, value },
  };
};

export const getEmbeddedToken = ({ generalToken, userRole }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
      params: {
        userRole: userRole,
      },
    };

    dispatch({
      type: 'EMBEDDED_TOKEN_GET_REPORT',
      payload: axios
        .get('/api/PowerBIEmbedded/GetPowerBIEmbedded', HEADER)
        .then((response) => response.data),
    });
  };
};
