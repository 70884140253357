import * as actionTypes from "../../constants/actionTypes";
import { updateObject } from "../Utility";

const initialState = {
  sidebarShow: true,
  getLocationStatusList: null,
  getLocationStatusLoading: false,
  locationTrackingLoading: false,
  locationTrackingLoadingPost: false,
  error: null,
  toast: [],
  showResult: [],
  locationTrackingList: null,
};

const onChangeSidebar = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const getLocationTrackingPadding = (state, action) => {
  return updateObject(state, { locationTrackingLoading: true });
};
const getLocationTrackingRejected = (state, action) => {
  return updateObject(state, {
    locationTrackingLoading: false,
    error: action.payload,
  });
};
const getLocationTrackingFulfilled = (state, action) => {
  return updateObject(state, {
    locationTrackingList: action.payload,
    locationTrackingLoading: false,
    error: null,
  });
};

const getLocationStatusPadding = (state, action) => {
  return updateObject(state, { getLocationStatusLoading: true });
};
const getLocationStatusRejected = (state, action) => {
  return updateObject(state, {
    getLocationStatusLoading: false,
    error: action.payload,
  });
};
const getLocationStatusFulfilled = (state, action) => {
  return updateObject(state, {
    getLocationStatusList: action.payload,
    getLocationStatusLoading: false,
    error: null,
  });
};
const postLocationTrackingPadding = (state, action) => {
  return updateObject(state, { locationTrackingLoadingPost: true });
};
const postLocationTrackingRejected = (state, action) => {
  return updateObject(state, {
    locationTrackingLoadingPost: false,
    error: action.payload,
  });
};
const postLocationTrackingFulfilled = (state, action) => {
  return updateObject(state, {
    //getLocationStatusList: action.payload,
    locationTrackingLoadingPost: false,
    error: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ON_CHANGE_SIDEBAR:
      return onChangeSidebar(state, action);
    case "LOCATION_STATUS_GET_PENDING":
      return getLocationStatusPadding(state, action);
    case "LOCATION_STATUS_GET_REJECTED":
      return getLocationStatusRejected(state, action);
    case "LOCATION_STATUS_GET_FULFILLED":
      return getLocationStatusFulfilled(state, action);

    case "LOCATION_TRACKING_GET_PENDING":
      return getLocationTrackingPadding(state, action);
    case "LOCATION_TRACKING_GET_REJECTED":
      return getLocationTrackingRejected(state, action);
    case "LOCATION_TRACKING_GET_FULFILLED":
      return getLocationTrackingFulfilled(state, action);

    case "LOCATION_TRACKING_COMMENT_POST_PENDING":
      return postLocationTrackingPadding(state, action);
    case "LOCATION_TRACKING_COMMENT_POST_REJECTED":
      return postLocationTrackingRejected(state, action);
    case "LOCATION_TRACKING_COMMENT_POST_FULFILLED":
      return postLocationTrackingFulfilled(state, action);
    default:
      return state;
  }
};

export default reducer;
