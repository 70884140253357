import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authCheckState } from "./store/actions";
import "./scss/style.scss";
import { CSpinner } from "@coreui/react";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">
      <CSpinner style={{ color: "#28CEF8" }} />
    </div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  const dispacth = useDispatch();

  const token = useSelector((state) => state.authResponse.token);

  React.useEffect(() => {
    dispacth(authCheckState());
  }, []);

  let routes = (
    <Switch>
      <Route
        exact
        path="/login"
        name="Login Page"
        render={(props) => <Login {...props} />}
      />
      <Route
        exact
        path="/404"
        name="Page 404"
        render={(props) => <Page404 {...props} />}
      />
      <Route
        exact
        path="/500"
        name="Page 500"
        render={(props) => <Page500 {...props} />}
      />
      <PrivateRoute
        path="/"
        isAuthenticated={
          typeof localStorage.getItem("accessToken") !== undefined &&
          localStorage.getItem("accessToken") !== null
            ? true
            : false
        }
      >
        <TheLayout />
      </PrivateRoute>
    </Switch>
  );

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>{routes}</React.Suspense>
    </HashRouter>
  );
};

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
