import * as actionTypes from "../../constants/actionTypes";
import { updateObject } from "../Utility";

const initialState = {
  loading: false,
  toast: [],
  showResult: [],
  divisions: [],
  subDivisions: [],
  surveys: [],
};

const updateSurveyShowResult = (state, action) => {
  return updateObject(state, {
    showResult: action.payload.message,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 5000,
        closeButton: true,
        fade: true,
        color: action.payload.color, // Add color to the toast
      },
    ],
  });
};

const updateSurveyLoading = (state, action) => {
  return updateObject(state, {
    loading: action.loading,
  });
};

const updateDivisions = (state, action) => {
  return updateObject(state, {
    divisions: action.divisions,
  });
};

const updateSubDivisions = (state, action) => {
  return updateObject(state, {
    subDivisions: action.subDivisions,
  });
};

const updateSurveys = (state, action) => {
  return updateObject(state, {
    surveys: action.surveys,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SURVEY_SHOWRESULT:
      return updateSurveyShowResult(state, action);
    case actionTypes.UPDATE_SURVEY_LOADING:
      return updateSurveyLoading(state, action);
    case actionTypes.GET_ALL_DIVISIONS:
      return updateDivisions(state, action);
    case actionTypes.GET_ALL_SUBDIVISIONS:
      return updateSubDivisions(state, action);
    case actionTypes.GET_SURVEYS:
      return updateSurveys(state, action);
    default:
      return state;
  }
};

export default reducer;
