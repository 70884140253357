export const HTTP_REQUEST = "HTTP_REQUEST";

export const SOFTBOX_SET_NEW_LINK = "SOFTBOX_SET_NEW_LINK";
export const SOFTBOX_SET_NEW_LINK_REQUEST = "SOFTBOX_SET_NEW_LINK_REQUEST";
export const SOFTBOX_SET_NEW_LINK_SUCCESS = "SOFTBOX_SET_NEW_LINK_SUCCESS";
export const SOFTBOX_SET_NEW_LINK_FAILURE = "SOFTBOX_SET_NEW_LINK_FAILURE";

export const ON_CHANGE_TEXT = "on_change_text";
export const AUTH_SUCCESS = "login_successful";
export const AUTH_FAIL = "login_fail";
export const AUTH_START = "login_aut";
export const AUTH_LOGOUT = "login_fail";
export const SET_AUTH_REDIRECT_PATH = "login_aut";

export const ON_CHANGE_SIDEBAR = "on_change_sidebar";

export const ON_CHANGE_VARIABLE = "on_change_variable";

export const ON_CHANGE_MODAL = "on_change_modal";

export const LICENSE_CONTROL_ON_CHANGE = "license_control_on_change";

export const MARKET_REGISTERY_ON_CHANGE = "market_registery_on_change";

export const SOFTBOX_ON_CHANGE = "softbox_on_change";

export const DEVICE_ON_CHANGE = "device_on_change";

export const INVENTORY_ON_CHANGE = "inventory_on_change";

export const USER_ON_CHANGE = "user_on_change";

export const EMBEDDED_TOKEN_ON_CHANGE = "embedded_token_on_change";

export const BACKUP_DATABASE_ON_CHANGE = "backup_database_on_change";

export const LOCATION_TRACKING_ON_CHANGE = "location_tracking_on_change";

export const LOCATION_ASSIGNMENT_ON_CHANGE = "location_assignment_on_change";

export const GET_TRENDPOS_REQUESTS = "trendpos_request_control";

export const TRENDPOS_REQUESTS_LOADING = "trendpos_request_loading";

export const UPDATE_SHOWRESULT = "update_showresult";

export const UPDATE_TRENDPOS_SHOWRESULT = "update_trendpos_showresult";

export const UPDATE_SURVEY_SHOWRESULT = "update_survey_showresult";

export const UPDATE_SURVEY_LOADING = "update_survey_loading";

export const GET_ALL_DIVISIONS = "get_all_divisions";

export const GET_ALL_SUBDIVISIONS = "get_all_subdivisions";

export const GET_SURVEYS = "get_surveys";
