import * as actionTypes from "../../constants/actionTypes";
import { updateObject } from "../Utility";

const initialState = {
  requests: [],
  requestCount: 0,
  requestsLoading: false,
  toast: [],
  showResult: [],
};

const updateShowResult = (state, action) => {
  return updateObject(state, {
    showResult: action.payload.message,
    toast: [
      ...state.toast,
      {
        position: "top-right",
        autohide: true && 8000,
        closeButton: true,
        fade: true,
        color: action.payload.color, // Add color to the toast
      },
    ],
  });
};

const newUserRequestTrendpos = (state, action) => {
  return updateObject(state, {
    requests: action.requests,
    requestCount: action.requestCount,
  });
};

const trendposRequestLoading = (state, action) => {
  return updateObject(state, {
    requestsLoading: action.requestsLoading,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TRENDPOS_SHOWRESULT:
      return updateShowResult(state, action);
    case actionTypes.GET_TRENDPOS_REQUESTS:
      return newUserRequestTrendpos(state, action);
    case actionTypes.TRENDPOS_REQUESTS_LOADING:
      return trendposRequestLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
