import { combineReducers } from "redux";
import authReducer from "./authReducer";
import sideBarReducer from "./sideBarReducer";
import LicenseControlReducer from "./LicenseControlReducer";
import ModalControlReducer from "./ModalControlReducer";
import MarketRegisteryReducer from "./MarketRegisteryReducer";
import SoftBoxControlReducer from "./SoftBoxControlReducer";
import CashRegisterControlReducer from "./CashRegisterControlReducer";
import InventoryLinkControlReducer from "./InventoryLinkControlReducer";
import UserControlReducer from "./UserControlReducer";
import EmbedTokenReducer from "./EmbedTokenReducer";
import BackupDatabaseReducer from "./BackupDatabaseReducer";
import LocationTrackingReducer from "./LocationTrackingReducer";
import LocationAssignmentReducer from "./LocationAssignmentReducer";
import NewUserRequestTrendposReducer from "./NewUserRequestTrendposReducer";
import SurveyReducer from "./SurveyReducer";

export default combineReducers({
  authResponse: authReducer,
  sideBarResponse: sideBarReducer,
  LicenseControlResponse: LicenseControlReducer,
  ModalControlResponse: ModalControlReducer,
  MarketRegisteryResponse: MarketRegisteryReducer,
  SoftBoxControlResponse: SoftBoxControlReducer,
  CashRegisterControlResponse: CashRegisterControlReducer,
  InventoryLinkControlResponse: InventoryLinkControlReducer,
  UserControlResponse: UserControlReducer,
  EmbedTokenResponse: EmbedTokenReducer,
  BackupDatabaseResponse: BackupDatabaseReducer,
  LocationTrackingResponse: LocationTrackingReducer,
  LocationAssignmentResponse: LocationAssignmentReducer,
  NewUserRequestTrendposResponse: NewUserRequestTrendposReducer,
  SurveyResponse: SurveyReducer,
});
