// NewUserRequestTrendPosAction.js

import {
  UPDATE_SURVEY_LOADING,
  UPDATE_SURVEY_SHOWRESULT,
  GET_ALL_DIVISIONS,
  GET_ALL_SUBDIVISIONS,
  GET_SURVEYS,
} from "../../constants/actionTypes";
import axios from "axios";

export const updateSurveyShowResult = (message, color = "#2eb85c") => {
  return {
    type: UPDATE_SURVEY_SHOWRESULT,
    payload: { message, color },
  };
};

export const updateSurveyLoading = (isLoading) => {
  return {
    type: UPDATE_SURVEY_LOADING,
    loading: isLoading,
  };
};

export const getAllDivisionsAndSubDivisions = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    let divisions = [];
    let subDivisions = [];

    const fetchDivisions = async () => {
      try {
        divisions = await axios
          .get("/api/Location/GetAllDivisions", HEADER)
          .then((response) => response.data);
      } catch (err) {
        console.log("Catch getAllDivisionsAndSubDivisions Error: " + err);
        dispatch(
          updateSurveyShowResult(
            "Şehir listesi alınırken bir hata oluştu.",
            "#f44336"
          )
        );
      }

      dispatch({ type: GET_ALL_DIVISIONS, divisions: divisions });
    };

    fetchDivisions();

    const fetchSubDivisions = async () => {
      try {
        subDivisions = await axios
          .get("/api/Location/GetAllSubDivs", HEADER)
          .then((response) => response.data);
      } catch (err) {
        console.log("Catch getAllDivisionsAndSubDivisions Error: " + err);
        dispatch(
          updateSurveyShowResult(
            "İlçe listesi alınırken bir hata oluştu.",
            "#f44336"
          )
        );
      }

      dispatch({ type: GET_ALL_SUBDIVISIONS, subDivisions: subDivisions });
    };

    fetchSubDivisions();
  };
};

export const createNewSurvey = (data, generalToken) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + generalToken,
      },
    };

    const userId = localStorage.getItem("userId");
    const request = {
      UserId: parseInt(userId),
      CustomerName: data.customerName,
      DivisionId: parseInt(data.division), // DivisionId as short
      SubdivisionId: parseInt(data.subdivision), // SubdivisionId as short
      BeginDate: data.beginDate,
      EndDate: data.endDate,
      Questions: data.questions.map((q, index) => ({
        LangId: "TR", // provide appropriate LangId if needed
        QuestionNumber: index + 1,
        QuestionType: q.questionType,
        QuestionText: q.questionText,
        PossibleAnswers: q.options.join(";"), // join the options with ";"
      })),
    };

    const createSurvey = async () => {
      dispatch(updateSurveyLoading(true));
      try {
        var result = await axios
          .post("/api/DynamicSurvey/CreateSurvey", request, HEADER)
          .then((response) => {
            dispatch(updateSurveyLoading(false));
            return response.data;
          });

        if (result.Success)
          dispatch(updateSurveyShowResult(result.message, "#2eb85c"));
        else dispatch(updateSurveyShowResult(result.message, "#f44336"));
      } catch (err) {
        console.log("Catch createSurvey Error: " + err);
        dispatch(updateSurveyLoading(false));
        dispatch(
          updateSurveyShowResult(
            "Anket oluşturulamadı! Bilinmeyen bir hata oluştu.",
            "#f44336"
          )
        );
      }
    };

    createSurvey();
  };
};

export const getSurveys = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    let surveys = [];

    const fetchSurveys = async () => {
      dispatch(updateSurveyLoading(true));
      try {
        surveys = await axios
          .get("/api/DynamicSurvey/GetSurveys", HEADER)
          .then((response) => response.data);
        dispatch(updateSurveyLoading(false));
      } catch (err) {
        console.log("Catch getSurveys Error: " + err);
        dispatch(updateSurveyLoading(false));
        dispatch(
          updateSurveyShowResult(
            "Anketler alınırken bir hata oluştu.",
            "#f44336"
          )
        );
      }

      console.log("Surveys: ", surveys);
      dispatch({ type: GET_SURVEYS, surveys: surveys });
    };

    fetchSurveys();
  };
};

export const updateSurveyIsActive = (data, generalToken) => {
  return async (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
        "Content-Type": "application/json",
      },
    };

    try {
      const result = await axios.put(
        `/api/DynamicSurvey/isActiveChange`,
        data,
        HEADER
      );
      dispatch(getSurveys({ generalToken }));
      if (result)
        dispatch(
          updateSurveyShowResult(
            "Anket durumu başarıyla güncellendi",
            "#2eb85c"
          )
        );
      else
        dispatch(
          updateSurveyShowResult("Anket durumu güncellenemedi.", "#f44336")
        );
    } catch (error) {
      dispatch(
        updateSurveyShowResult(
          "Anket durumu güncellenemedi:" + error,
          "#f44336"
        )
      );
    }
  };
};
