import axios from "axios";

import {
  MARKET_REGISTERY_ON_CHANGE,
  UPDATE_SHOWRESULT,
} from "../../constants/actionTypes";
import { modalOnchangeActionFunction } from "./index";

export const MarketRegisteryONCHANGE = ({ props, value }) => {
  return {
    type: MARKET_REGISTERY_ON_CHANGE,
    payload: { props, value },
  };
};

export const MarketRegistrySHOWRESULT = (message) => {
  return {
    type: UPDATE_SHOWRESULT,
    payload: message,
  };
};

export const MarketRegisteryGETLOCATIONTYPE = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    dispatch({
      type: "MARKET_REGISTERY_GET_LOCATION_TYPE",
      payload: axios
        .get("/api/Location/GetLocationTypes", HEADER)
        .then((response) => response.data),
    });
  };
};

export const LocationProviderInstallerGET = ({ generalToken, locationId }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
      params: {
        locationId: parseInt(locationId),
      },
    };
    dispatch({
      type: "LOCATION_PROVIDER_INSTALLER_ALL",
      payload: axios
        .get("/api/Location/ListLocationProviderInstaller", HEADER)
        .then((response) => response.data),
    });
  };
};

export const MarketRegisteryGETALLLOCATION = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };

    dispatch({
      type: "MARKET_REGISTERY_GET_ALL_LOCATION",
      payload: axios
        .get("/api/Location/GetAllLocations", HEADER)
        .then((response) => response.data),
    });
  };
};

export const MarketRegisteryGETALLPROVIDER = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const res = dispatch({
      type: "MARKET_REGISTERY_GET_ALL_PROVIDER",
      payload: axios
        .get("/api/Location/GetAllProviders", HEADER)
        .then((response) => response.data),
    });
    console.log(res);
  };
};

export const MarketRegisterySETLOCATIONUPDATE = ({
  generalToken,
  StoreId,
  LocationId,
  LocationName,
  locationTypeId,
  CompanySize,
  CountryId,
  DivisionId,
  SubDivisionId,
  SubDivision2Id,
  Address,
  IsActive,
  providerId,
  CoordinateLatitude,
  CoordinateLongitude,
  CompanyTurnover,
  CompanyTakeaway,
  ContactName,
  ContactSurname,
  ContactPhone,
  Installer,
  registrationDate,
  canceledDate,
  CustomerCode,
  ControlType,
  TransferredTo,
  CompanyDescription,
  CompanyBarcodeReaderId,
  HasInventoryApp,
  HasBarcodeReader,
  HasBarcodeReaderContract,
  HasGeneralContract,
  AlkoholicTabdkId,
  CigaretteTabdkId,
  ProviderInstaller,
  CompanyTaxNo,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = {
      StoreId,
      LocationId,
      LocationName,
      LocationTypeId: locationTypeId === "" ? null : parseInt(locationTypeId),
      LocationSize: "",
      CountryId,
      DivisionId,
      SubDivisionId,
      SubDivision2Id,
      Address,
      IsActive,
      ProviderId: providerId === "" ? null : parseInt(providerId),
      CoordinateLatitude,
      CoordinateLongitude,
      CompanySize,
      CompanyTurnover,
      CompanyTakeaway,
      ContactName,
      ContactSurname,
      ContactPhone,
      Installer,
      registrationDate:
        registrationDate === "Invalid date" ? null : registrationDate,
      canceledDate: canceledDate === "Invalid date" ? null : canceledDate,
      CustomerCode: CustomerCode?.trim() === "" ? null : CustomerCode?.trim(),
      ControlType: ControlType?.trim(),
      TransferredTo:
        TransferredTo?.trim() === "" ? null : TransferredTo?.trim(),
      CompanyDescription:
        CompanyDescription?.trim() === "" ? null : CompanyDescription?.trim(),
      CompanyBarcodeReaderId:
        CompanyBarcodeReaderId?.trim() === ""
          ? null
          : CompanyBarcodeReaderId?.trim(),
      HasInventoryApp,
      HasBarcodeReader,
      HasBarcodeReaderContract,
      HasGeneralContract,
      AlkoholicTabdkId,
      CigaretteTabdkId,
      ProviderInstaller,
      CompanyTaxNo,
    };

    const RES = dispatch({
      type: "MARKET_REGISTERY_SET_LOCATION_UPDATE",
      payload: axios
        .post("/api/Location/EditLocation", data, HEADER)
        .then((response) => response.data),
    });
    // console.log(RES);

    RES.then((res) => {
      if (
        res.action.type === "MARKET_REGISTERY_SET_LOCATION_UPDATE_FULFILLED"
      ) {
        dispatch(MarketRegisteryGETALLLOCATION({ generalToken: generalToken }));
        dispatch(
          modalOnchangeActionFunction({
            props: "marketUpdateModal",
            value: false,
          })
        );
        dispatch(
          modalOnchangeActionFunction({
            props: "marketRegisteryData",
            value: null,
          })
        );
      }
    });
  };
};

export const MarketRegisterySETLOCATIONREGISTRY = ({
  generalToken,
  CustomerName,
  CustomerPhone,
  CustomerAddress,
  CoordinateLatitude,
  CoordinateLongitude,
  IsActive,
  LocationTypeId,
  LocationSize,
  CountryId,
  DivisionId,
  SubDivisionId,
  SubDivision2Id,
  ProviderId,
  CompanySize,
  CompanyTurnover,
  CompanyTakeaway,
  ContactName,
  ContactSurname,
  Installer,
  registrationDate,
  canceledDate,
  CustomerCode,
  ControlType,
  TransferredTo,
  CompanyDescription,
  CompanyBarcodeReaderId,
  HasInventoryApp,
  HasBarcodeReader,
  HasBarcodeReaderContract,
  HasGeneralContract,
  MARKETINFORMATION,
  AlkoholicTabdkId,
  CigaretteTabdkId,
  CompanyTaxNo,
  ProviderInstaller,
  InstallationType,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = {
      CustomerName,
      CustomerPhone,
      CustomerAddress,
      CoordinateLatitude,
      CoordinateLongitude,
      IsActive,
      LocationTypeId: parseInt(LocationTypeId),
      LocationSize,
      CountryId,
      DivisionId: parseInt(DivisionId),
      SubDivisionId: parseInt(SubDivisionId),
      SubDivision2Id: parseInt(SubDivision2Id),
      ProviderId: ProviderId === "" ? null : parseInt(ProviderId),
      CompanySize,
      CompanyTurnover,
      CompanyTakeaway,
      ContactName,
      ContactSurname,
      Installer,
      registrationDate,
      canceledDate: canceledDate === undefined ? null : canceledDate,
      CustomerCode: CustomerCode.trim() === "" ? null : CustomerCode,
      ControlType,
      TransferredTo: TransferredTo.trim() === "" ? null : TransferredTo,
      CompanyDescription:
        CompanyDescription.trim() === "" ? null : CompanyDescription,
      CompanyBarcodeReaderId:
        CompanyBarcodeReaderId.trim() === "" ? null : CompanyBarcodeReaderId,
      HasInventoryApp,
      HasBarcodeReader,
      HasBarcodeReaderContract,
      HasGeneralContract,
      AlkoholicTabdkId,
      CigaretteTabdkId,
      CompanyTaxNo,
      ProviderInstaller,
      InstallationType,
    };

    const RES = dispatch({
      type: "MARKET_REGISTERY_SET_LOCATION_REGISTRY",
      payload: axios
        .post("/api/Location/AddCustomer", data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (
        res.action.type === "MARKET_REGISTERY_SET_LOCATION_REGISTRY_FULFILLED"
      ) {
        dispatch(
          MarketRegisteryONCHANGE({
            props: "divisions",
            value: {
              countryId: "TR",
              divisionId: 0,
              subDivisionId: 0,
              subDivision2Id: 0,
              locationTypeId: 0,
              ProviderId: 0,
              locationSize: "",
              companyTurnover: "",
              companyTakeaway: "",
              hasInventoryApp: "",
              hasBarcodeReader: "",
              hasBarcodeReaderContract: "",
              hasGeneralContract: "",
              alkoholicTabdkId: "",
              cigaretteTabdkId: "",
              companyTaxNo: "",
              installationType: "",
            },
          })
        );
        dispatch(
          MarketRegisteryONCHANGE({
            props: "installerList",
            value: [],
          })
        );
        dispatch(MarketRegisteryGETALLLOCATION({ generalToken: generalToken }));
        dispatch(
          modalOnchangeActionFunction({
            props: "marketRegistryModal",
            value: false,
          })
        );
        dispatch(
          modalOnchangeActionFunction({
            props: "marketRegisteryData",
            value: null,
          })
        );
      }
    });
  };
};

export const MarketRegisteryGETALLDEVISION = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const res = dispatch({
      type: "MARKET_REGISTERY_GET_ALL_DEVISION",
      payload: axios
        .get("/api/Location/GetAllAddress", HEADER)
        .then((response) => response.data),
    });
  };
};

export const MarketRegisterySETLOCATIONMARKET = ({
  companyId,
  locationId,
  generalToken,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = {
      company_id: companyId,
      location_id: locationId,
      is_active: true,
    };
    const RES = dispatch({
      type: "MARKET_REGISTERY_SET",
      payload: axios
        .post("/api/InventoryLink/Add", data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {});
  };
};
