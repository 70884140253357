import axios from "axios";

import { BACKUP_DATABASE_ON_CHANGE } from "../../constants/actionTypes";

export const backupDatabaseONCHANGE = ({ props, value }) => {
  return {
    type: BACKUP_DATABASE_ON_CHANGE,
    payload: { props, value },
  };
};

export const backupdatabaseGETCOMPANYID = ({ generalToken, _companyId }) => {
  return (dispatch) => {
    if (_companyId.length !== 64) {
      dispatch({
        type: "BACKUP_DATABASE_DOWNLOAD_SET_COMPANYID_REJECTED",
        payload: "Firma ID yanlış Girilmiş olabilir.",
      });
    } else {
      const HEADER = {
        headers: {
          authorization: "Bearer " + generalToken,
        },
        params: {
          companyId: _companyId,
        },
      };

      dispatch({
        type: "BACKUP_DATABASE_GET_COMPANYID",
        payload: axios
          .get("/api/Backup/GetBackupCompanyIds", HEADER)
          .then((response) => response.data),
      });
    }
  };
};

const teststring = "";
export const backupdatabaseDownloadSETCOMPANY = ({
  generalToken,
  _companyId,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
      params: {
        companyId: _companyId,
      },
    };

    let RES = dispatch({
      type: "BACKUP_DATABASE_DOWNLOAD_SET_COMPANYID",
      payload: axios
        .get("/api/Backup/GetBackupDownload", HEADER)
        .then((response) => response.data),
    });
    const array = [];
    RES.then((res) => {
      if (
        "BACKUP_DATABASE_DOWNLOAD_SET_COMPANYID_FULFILLED" === res.action.type
      ) {
        var uint8View = new Uint8Array(_base64ToArrayBuffer(res.value));
        Download(uint8View, "application/x-7z-compressed");

        /* res.value.forEach((i) => {
          // var uint8View = new Uint8Array(_base64ToArrayBuffer(i));
          // Array.prototype.push.apply(array, uint8View);
        });*/
      }
    });
  };
};
function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function Download(arrayBuffer, type) {
  var blob = new Blob([arrayBuffer], { type: type });
  var url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", "StockDatabase.7z");
  document.body.appendChild(a);
  a.click();
}
