import { updateObject } from '../Utility';
import * as type from '../../constants/actionTypes';

const initialState = {
  loading: false,
  error: null,
  toast: [],
  showResult: [],

  getAllUserState: [],
  getAllUserLoading: false,

  getUserDetailsState: { roleList: [] },
  getUserDetailsLoading: false,

  setUserRegisterLoading: false,

  setUserData: {
    email: '',
    password: '',
    passwordAgain: '',
    roleIdList: '',
    firstName: '',
    lastName: '',
    mobile: '',
    isActive: false,
  },

  getUserAllRoleLoading: false,
  getUserAllRoleData: [],
};

const userOnChange = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};
const getAllUserPadding = (state, action) => {
  return updateObject(state, { getAllUserLoading: true });
};
const getAllUserRejected = (state, action) => {
  return updateObject(state, {
    getAllUserLoading: false,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
    error: action.payload,
  });
};

const getAllUserSuccess = (state, action) => {
  return updateObject(state, {
    getAllUserState: action.payload,
    getAllUserLoading: false,
  });
};

const getUserDetailsPadding = (state, action) => {
  return updateObject(state, { getUserDetailsLoading: true });
};
const getUserDetailsRejected = (state, action) => {
  return updateObject(state, {
    getUserDetailsLoading: false,
    showResult: 'Bir hata meydana geldi ' + action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
    error: action.payload,
  });
};

const getUserDetailsSuccess = (state, action) => {
  return updateObject(state, {
    getUserDetailsState: action.payload,
    getUserDetailsLoading: false,
  });
};

const setUserRegisterPadding = (state, action) => {
  return updateObject(state, { setUserRegisterLoading: true });
};
const setUserRegisterRejected = (state, action) => {
  return updateObject(state, {
    setUserRegisterLoading: false,
    showResult: action.payload.response.data.errors,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
    error: action.payload,
  });
};

const setUserRegisterSuccess = (state, action) => {
  return updateObject(state, {
    showResult: 'Kayıt başarılı',
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
      },
    ],
    setUserRegisterLoading: false,
  });
};
const getUserAllRolePadding = (state, action) => {
  return updateObject(state, { getUserAllRoleLoading: true });
};
const getUserAllRoleRejected = (state, action) => {
  return updateObject(state, {
    getUserAllRoleLoading: false,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
    error: action.payload,
  });
};

const getUserAllRoleSuccess = (state, action) => {
  const result = [];
  for (let key in action.payload) {
    result.push({
      id: action.payload[key].roleName,
      config: action.payload[key],
      select: false,
    });
  }

  return updateObject(state, {
    getUserAllRoleData: result,
    getUserAllRoleLoading: false,
  });
};

const setUserUpdatePadding = (state, action) => {
  return updateObject(state, { setUserRegisterLoading: true });
};
const setUserUpdateRejected = (state, action) => {
  return updateObject(state, {
    setUserRegisterLoading: false,
    showResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 3000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
    error: action.payload,
  });
};

const setUserUpdateSuccess = (state, action) => {
  return updateObject(state, {
    setUserRegisterLoading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.USER_ON_CHANGE:
      return userOnChange(state, action);
    case 'USER_SET_USER_UPDATE_PENDING':
      return setUserUpdatePadding(state, action);
    case 'USER_SET_USER_UPDATE_REJECTED':
      return setUserUpdateRejected(state, action);
    case 'USER_SET_USER_UPDATE_FULFILLED':
      return setUserUpdateSuccess(state, action);
    case 'USER_SET_REGISTER_PENDING':
      return setUserRegisterPadding(state, action);
    case 'USER_SET_REGISTER_REJECTED':
      return setUserRegisterRejected(state, action);
    case 'USER_SET_REGISTER_FULFILLED':
      return setUserRegisterSuccess(state, action);
    case 'GET_USER_ALL_ROLE_PENDING':
      return getUserAllRolePadding(state, action);
    case 'GET_USER_ALL_ROLE_REJECTED':
      return getUserAllRoleRejected(state, action);
    case 'GET_USER_ALL_ROLE_FULFILLED':
      return getUserAllRoleSuccess(state, action);
    case 'USER_GET_ALL_USER_PENDING':
      return getAllUserPadding(state, action);
    case 'USER_GET_ALL_USER_REJECTED':
      return getAllUserRejected(state, action);
    case 'USER_GET_ALL_USER_FULFILLED':
      return getAllUserSuccess(state, action);
    case 'USER_GET_USER_DETAILS_PENDING':
      return getUserDetailsPadding(state, action);
    case 'USER_GET_USER_DETAILS_REJECTED':
      return getUserDetailsRejected(state, action);
    case 'USER_GET_USER_DETAILS_FULFILLED':
      return getUserDetailsSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
