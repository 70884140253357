import axios from 'axios';

import { DEVICE_ON_CHANGE } from '../../constants/actionTypes';
import { modalOnchangeActionFunction } from './index';

export const deviceONCHANGE = ({ props, value }) => {
  return {
    type: DEVICE_ON_CHANGE,
    payload: { props, value },
  };
};

export const deviceGETCASHREGISTER = ({ generalToken }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    let RES = dispatch({
      type: 'DEVICE_GET_CASH_REGISTER',
      payload: axios
        .get('/api/CashRegister/Get', HEADER)
        .then((response) => response.data),
    });
  };
};

export const deviceSETCREATECASHREGISTER = ({
  generalToken,
  cashRegisterId,
  cashRegisterNo,
  cashRegisterIp,
  imei,
  dataNo,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    const data = {
      cashRegisterId,
      cashRegisterNo,
      cashRegisterIp,
      imei,
      dataNo,
    };

    const RES = dispatch({
      type: 'DEVICE_SET_CREATE_CASH_REGISTER',
      payload: axios
        .post('/api/CashRegister/CreateCashRegister', data, HEADER)
        .then((response) => response.data),
    });
    RES.then((res) => {
      if (res.action.type === 'DEVICE_SET_CREATE_CASH_REGISTER_FULFILLED') {
        dispatch(deviceGETCASHREGISTER({ generalToken: generalToken }));
        dispatch(
          modalOnchangeActionFunction({
            props: 'cashregisterModal',
            value: false,
          })
        );
      }
    });
  };
};

export const deviceSETCREATECASHUPDATER = ({
  generalToken,
  cashRegisterId,
  cashRegisterNo,
  locationId,
  cashRegisterIp,
  lastSignal,
  imei,
  dataNo,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    const data = {
      cashRegisterId,
      cashRegisterNo,
      locationId,
      cashRegisterIp,
      lastSignal,
      imei,
      dataNo,
    };

    const RES = dispatch({
      type: 'DEVICE_SET_CREATE_CASH_UPDATER',
      payload: axios
        .post('/api/CashRegister/Edit', data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === 'DEVICE_SET_CREATE_CASH_UPDATER_FULFILLED') {
        dispatch(deviceGETCASHREGISTER({ generalToken: generalToken }));
        dispatch(
          modalOnchangeActionFunction({
            props: 'cashregisterModal',
            value: false,
          })
        );
      }
    });
  };
};

export const deviceSETCREATECASHASSIGN = ({
  generalToken,
  cashRegisterId,
  locationId,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    const data = {
      cashRegisterId,
      locationId,
    };

    const RES = dispatch({
      type: 'DEVICE_SET_CREATE_CASH_ASSIGN',
      payload: axios
        .post('/api/CashRegister/AssignCashRegister', data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === 'DEVICE_SET_CREATE_CASH_ASSIGN_FULFILLED') {
        dispatch(deviceGETCASHREGISTER({ generalToken: generalToken }));
        dispatch(
          modalOnchangeActionFunction({ props: 'linkDoModal', value: false })
        );
        dispatch(
          modalOnchangeActionFunction({
            props: 'commonModal',
            value: false,
          })
        );
      }
    });
  };
};

export const deviceSETCREATECUNASHASSIGN = ({
  generalToken,
  cashregisterId,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: 'Bearer ' + generalToken,
      },
    };
    const data = {
      cashregisterId,
    };

    const RES = dispatch({
      type: 'DEVICE_SET_CREATE_CASH_UNASSIGN',
      payload: axios
        .post('/api/CashRegister/UnAssignCashRegister', data, HEADER)
        .then((response) => response.data),
    });

    RES.then((res) => {
      if (res.action.type === 'DEVICE_SET_CREATE_CASH_UNASSIGN_FULFILLED') {
        dispatch(deviceGETCASHREGISTER({ generalToken: generalToken }));
        dispatch(
          modalOnchangeActionFunction({
            props: 'yesOrNoModal',
            value: false,
          })
        );
      }
    });
  };
};
