import { LOCATION_TRACKING_ON_CHANGE } from "../../constants/actionTypes";
import axios from "axios";

export const locationTrackingOnchangeActionFunction = ({ props, value }) => {
  return {
    type: LOCATION_TRACKING_ON_CHANGE,
    payload: { props, value },
  };
};

export const locationStatusGET = ({ generalToken, _filter }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
      params: {
        filter: _filter,
        prevdate: 3,
      },
    };
    dispatch({
      type: "LOCATION_STATUS_GET",
      payload: axios
        .get("/api/LocationTracking/Get", HEADER)
        .then((response) => response.data),
    });
  };
};
export const locationTrackingGET = ({ generalToken, _locationId }) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
      params: {
        locationId: _locationId,
      },
    };
    dispatch({
      type: "LOCATION_TRACKING_GET",
      payload: axios
        .get("/api/LocationTracking/GetLocationTracking", HEADER)
        .then((response) => response.data),
    });
  };
};

export const locationTrackingCommentPOST = ({
  generalToken,
  body,
  _filter,
}) => {
  return (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };

    const data = {
      locationId: body.locationId,
      userId: body.userId,
      communicationType: parseInt(body.communicationType),
      communicationReason: parseInt(body.communicationReason),
      locationStatus: parseInt(body.locationStatus),
      comment: body.comment,
    };
  
    const RES = dispatch({
      type: "LOCATION_TRACKING_COMMENT_POST",
      payload: axios.post(
        "/api/LocationTracking/AddLocationTracking",
        data,
        HEADER
      ),
    });
    RES.then((res) => {
      if (res.action.type === "LOCATION_TRACKING_COMMENT_POST_FULFILLED") {
        dispatch(locationStatusGET({ generalToken: generalToken, _filter }));
        dispatch(
          locationTrackingGET({
            generalToken: generalToken,
            _locationId: body.locationId,
          })
        );
      }
    });
  };
};
