import { LOCATION_ASSIGNMENT_ON_CHANGE } from "../../constants/actionTypes";
import axios from "axios";

export const locationAssignmentOnchangeActionFunction = ({ props, value }) => {
  return {
    type: LOCATION_ASSIGNMENT_ON_CHANGE,
    payload: { props, value },
  };
};

export const locationAssignmentPOST = ({ generalToken, body }) => {
  return async (dispatch) => {
    const HEADER = {
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };
    const data = body;
    dispatch({
      type: "LOCATION_ASSIGNMENT_POST",
      payload: axios
        .post(`/api/LocationAssignment/LocationAssignment`, data, HEADER)
        .catch((err) => console.log(err)),
    });
  };
};

export const AutoTransferData = ({ generalToken, body }) => {
  return (dispatch) => {
    const HEADER = {
      params: {
        sheet: body?.sheet,
      },
      headers: {
        authorization: "Bearer " + generalToken,
      },
    };

    dispatch({
      type: "UPDATE_MAIN_PRODUCT",
      payload: axios.get(`/api/ProductsUpdate/${body?.apiName}`, HEADER),
    });
  };
};
