import { updateObject } from "../Utility";
import * as type from '../../constants/actionTypes';

const initialState = {
    getCashRegisterLoading: false,
    getCashRegisterData: [],

    setCreateCashRegisterLoading: false,
    setCreateCashRegisterData: [],

    locationId:null,

    editActive:false,

    error: null,
    cashRegisterDataState: {
        cashregisterId: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Kasa ID',
                lg: 6
            },
            value: '',
        },
        cashRegisterNo: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Kasa NO',
                lg: 6
            },
            value: '',
        },
        cashRegisterIp: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Kasa IP',
                lg: 6
            },
            value: '',
        },

  
        imei: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'IMEI',
                lg: 6
            },
            value: '',
        },
        dataNo: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Data Telefon NO',
                lg: 6
            },
            value: '',
        },



    },
    toast: [],
    showResult: [],

};



const setCreateCashRegisterPadding = (state, action) => {
    return updateObject(state, { setCreateCashRegisterLoading: true });
};
const setCreateCashRegisterRejected = (state, action) => {
    return updateObject(state, {
        showResult: "Bir hata meydana geldi\n "+action.payload,
        setCreateCashRegisterData: action.payload,
        setCreateCashRegisterLoading: false,
        toast: [...state.toast, { position: "top-right", autohide: true && 3000, closeButton: true, fade: true,color: '#f86c6b' }],
    });
};
const setCreateCashRegisterSuccess = (state, action) => {
    return updateObject(state, {
        setCreateCashRegisterLoading: false,
        error: action.payload,
        showResult: "Cihaz ekleme başarılı\n "+action.payload,
        toast: [...state.toast, { position: "top-right", autohide: true && 3000, closeButton: true, fade: true }],
    });
};




const getcashRegisterPadding = (state, action) => {
    return updateObject(state, { getCashRegisterLoading: true });
};
const getcashRegisterSuccess = (state, action) => {
    return updateObject(state, {
        getCashRegisterData: action.payload,
        getCashRegisterLoading: false,
    });
};
const getcashRegisterRejected = (state, action) => {
    return updateObject(state, {
        getCashRegisterLoading: false,
        error: action.payload,
        showResult: action.payload,
        toast: [...state.toast, { position: "top-right", autohide: true && 3000, closeButton: true, fade: true, color: '#f86c6b' }],
    });
};



const setCreateCashUpdaterPadding = (state, action) => {
    return updateObject(state, { setCreateCashRegisterLoading: true });
};
const setCreateCashUpdaterSuccess = (state, action) => {
    return updateObject(state, {
        setCreateCashRegisterData: action.payload,
        setCreateCashRegisterLoading: false,
    });
};
const setCreateCashUpdaterRejected = (state, action) => {
    return updateObject(state, {
        setCreateCashRegisterLoading: false,
        error: action.payload,
        showResult: action.payload,
        toast: [...state.toast, { position: "top-right", autohide: true && 3000, closeButton: true, fade: true, color: '#f86c6b' }],
    });
};



const setCreateCashAssignPadding = (state, action) => {
    return updateObject(state, { setCreateCashRegisterLoading: true });
};
const setCreateCashAssignSuccess = (state, action) => {
    return updateObject(state, {
        setCreateCashRegisterData: action.payload,
        setCreateCashRegisterLoading: false,
    });
};
const setCreateCashAssignRejected = (state, action) => {
    return updateObject(state, {
        setCreateCashRegisterLoading: false,
        error: action.payload,
        showResult: action.payload,
        toast: [...state.toast, { position: "top-right", autohide: true && 3000, closeButton: true, fade: true, color: '#f86c6b' }],
    });
};


const setCreateCashUnAssignPadding = (state, action) => {
    return updateObject(state, { setCreateCashRegisterLoading: true });
};
const setCreateCashUnAssignSuccess = (state, action) => {
    return updateObject(state, {
        setCreateCashRegisterData: action.payload,
        toast: [...state.toast, { position: "top-right", autohide: true && 3000, closeButton: true, fade: true }],
        setCreateCashRegisterLoading: false,
    });
};
const setCreateCashUnAssignRejected = (state, action) => {
    return updateObject(state, {
        setCreateCashRegisterLoading: false,
        error: action.payload,
        showResult: action.payload,
        toast: [...state.toast, { position: "top-right", autohide: true && 3000, closeButton: true, fade: true, color: '#f86c6b' }],
    });
};


const onChangeDevice = (state, action) => {
    return updateObject(state, { [action.payload.props]: action.payload.value });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case type.DEVICE_ON_CHANGE:
            return onChangeDevice(state, action);

        case "DEVICE_GET_CASH_REGISTER_PENDING":
            return getcashRegisterPadding(state, action);
        case "DEVICE_GET_CASH_REGISTER_REJECTED":
            return getcashRegisterRejected(state, action);
        case "DEVICE_GET_CASH_REGISTER_FULFILLED":
            return getcashRegisterSuccess(state, action);


        case "DEVICE_SET_CREATE_CASH_REGISTER_PENDING":
            return setCreateCashRegisterPadding(state, action);
        case "DEVICE_SET_CREATE_CASH_REGISTER_REJECTED":
            return setCreateCashRegisterRejected(state, action);
        case "DEVICE_SET_CREATE_CASH_REGISTER_FULFILLED":
            return setCreateCashRegisterSuccess(state, action);
        
        case "DEVICE_SET_CREATE_CASH_UPDATER_PENDING":
                return setCreateCashUpdaterPadding(state, action);
        case "DEVICE_SET_CREATE_CASH_UPDATER_REJECTED":
                return setCreateCashUpdaterRejected(state, action);
        case "DEVICE_SET_CREATE_CASH_UPDATER_FULFILLED":
                return setCreateCashUpdaterSuccess(state, action);


        case "DEVICE_SET_CREATE_CASH_ASSIGN_PENDING":
                    return setCreateCashAssignPadding(state, action);
        case "DEVICE_SET_CREATE_CASH_ASSIGN_REJECTED":
                    return setCreateCashAssignRejected(state, action);
        case "DEVICE_SET_CREATE_CASH_ASSIGN_FULFILLED":
                    return setCreateCashAssignSuccess(state, action);


         case "DEVICE_SET_CREATE_CASH_UNASSIGN_PENDING":
                        return setCreateCashUnAssignPadding(state, action);
        case "DEVICE_SET_CREATE_CASH_UNASSIGN_REJECTED":
                        return setCreateCashUnAssignRejected(state, action);
        case "DEVICE_SET_CREATE_CASH_UNASSIGN_FULFILLED":
                        return setCreateCashUnAssignSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
