import { updateObject } from "../Utility";
import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  commonModal: false,
  modalData: [],
  dangerModal: false,
  ModalColor: "",
  linkModal: false,
  marketUpdateModal: false,
  marketRegistryModal: false,
  marketRegisteryData: null,

  cashregisterModal: false,

  yesOrNoModal: false,

  linkDoModal: false,

  userModal: false,

  userModalRegister: false,

  marketRegisteryData1: {
    companyId: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Şirket ID",
        lg: 6,
        disabled: true,
      },
      value: "",
    },
    companyAddress: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Şirket Adres",
        lg: 12,
      },
      value: "",
    },

    companyName: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Şirket İsmi",
        lg: 6,
      },
      value: "",
    },

    companyPhone: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Şirket Telefon",
        lg: 6,
      },
      value: "",
    },

    companyUuid: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Şirket UUID",
        lg: 6,
      },
      value: "",
    },

    companyXY: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Şirket Koodinat",
        lg: 6,
      },
      value: "",
    },

    isActive: {
      elementType: "input",
      elementConfig: {
        type: "name",
        placeholder: "Şirket Aktiflik Durumu",
        lg: 6,
      },
      value: "",
    },
  },
};

const modalOnchangeReducer = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ON_CHANGE_MODAL:
      return modalOnchangeReducer(state, action);
    default:
      return state;
  }
};

export default reducer;
