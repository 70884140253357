import { updateObject } from '../Utility';
import * as type from '../../constants/actionTypes';

const initialState = {
  loading: false,
  dummyLicenceTable: [],
  locationLoading: false,
  error: null,
  rowLoading: false,
  rowResult: null,
  toast: [],
  getLocations: [],
  tableStaticToaster: true,
  tableStaticToasterItem: [],

};

const setLicenceTableSuccess = (state, action) => {
  return updateObject(state, {
    dummyLicenceTable: action.payload,
    loading: false,
  });
};
const setLicenceTablePadding = (state, action) => {
  return updateObject(state, { loading: true });
};
const setLicenceTablerRejected = (state, action) => {
  return updateObject(state, { loading: false, error: action.payload });
};


const setStatuChangeLicenceTableSuccess = (state, action) => {
  return updateObject(state, {
    rowResult: action.payload.data,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 2000,
        closeButton: true,
        fade: true,
      },
    ],
    rowLoading: false,
  });
};
const setStatuChangeLicenceTablePadding = (state, action) => {
  return updateObject(state, { rowLoading: true });
};
const setStatuChangeLicenceTablerRejected = (state, action) => {
  return updateObject(state, { rowLoading: false, error: action.payload });
};

const getLocationsPadding = (state, action) => {
  return updateObject(state, { locationLoading: true });
};
const getLocationsRejected = (state, action) => {
  return updateObject(state, { locationLoading: false, error: action.payload });
};
const getLocationsFulfilled = (state, action) => {
  return updateObject(state, {
    getLocations: action.payload,
    locationLoading: false,
  });
};

const setLocationLinkPadding = (state, action) => {
  return updateObject(state, { rowLoading: true });
};
const setLocationLinkRejected = (state, action) => {
  return updateObject(state, {
    rowLoading: false,
    rowResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 4000,
        closeButton: true,
        fade: true,
        color: '#f86c6b',
      },
    ],
    error: action.payload,
  });
};

const setLocationLinkFulfilled = (state, action) => {
  return updateObject(state, {
    rowResult: action.payload,
    toast: [
      ...state.toast,
      {
        position: 'top-right',
        autohide: true && 2000,
        closeButton: true,
        fade: true,
      },
    ],
    rowLoading: false,
  });
};

const onChangeLicense = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LICENSE_CONTROL_GET_MARKET_PENDING':
      return setLicenceTablePadding(state, action);
    case 'LICENSE_CONTROL_GET_MARKET_REJECTED':
      return setLicenceTablerRejected(state, action);
    case 'LICENSE_CONTROL_GET_MARKET_FULFILLED':
      return setLicenceTableSuccess(state, action);

    case 'LICENSE_CONTROL_MARKET_STATUS_CHANGE_PENDING':
      return setStatuChangeLicenceTablePadding(state, action);
    case 'LICENSE_CONTROL_MARKET_STATUS_CHANGE_REJECTED':
      return setStatuChangeLicenceTablerRejected(state, action);
    case 'LICENSE_CONTROL_MARKET_STATUS_CHANGE_FULFILLED':
      return setStatuChangeLicenceTableSuccess(state, action);
    case 'LOCATIONS_GET_PENDING':
      return getLocationsPadding(state, action);
    case 'LOCATIONS_GET_REJECTED':
      return getLocationsRejected(state, action);
    case 'LOCATIONS_GET_FULFILLED':
      return getLocationsFulfilled(state, action);
    case 'LOCATION_LINK_SET_PENDING':
      return setLocationLinkPadding(state, action);
    case 'LOCATION_LINK_SET_REJECTED':
      return setLocationLinkRejected(state, action);
    case 'LOCATION_LINK_SET_FULFILLED':
      return setLocationLinkFulfilled(state, action);
    case type.LICENSE_CONTROL_ON_CHANGE:
      return onChangeLicense(state, action);
    default:
      return state;
  }
};

export default reducer;
